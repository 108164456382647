import React from "react";
import { PropTypes } from "prop-types";
import { Row, Col, Typography } from "antd";
import { Link } from "gatsby";
import Image from "../ImageQuerys/LocalImages";
import CtaPrimary from "../Buttons/primary-cta";

const { Title } = Typography;

const Restaurants = (props) => {
  const { data, nav, image, bgText, title, subtext, noHeading, noCta } = props;
  return (
    <div
      className="restuarant"
      id={nav}
      style={{ padding: 0, marginBottom: 72 }}
    >
      {!noHeading && (
        <>
          <Row className="pos-section__text" style={{ marginBottom: 40 }}>
            <Col span={24}>
              <Title className="restuarant-main-title" level={2}>
                {title}
                <span className="blue_dot">.</span>{" "}
              </Title>
            </Col>
            <p className="restuarant-main-para">{subtext}</p>
          </Row>
          {/* <div className="vertical-pill" /> */}
          <Image
            imageName={image}
            className="hardware"
            alt=""
            style={{
              width: "100%",
              // position: "absolute",
              top: "30%",
              maxWidth: "100%",
            }}
          />
        </>
      )}
      <div className="restaurant-features">
        <Row gutter={[32, 32]}>
          {data.map((item, idx) => (
            <Col
              key={idx.toString()}
              sm={{ span: 12, offset: 0 }}
              md={{ span: 12, offset: 0 }}
              lg={{
                span: 8,
                offset:
                  // eslint-disable-next-line no-nested-ternary
                  idx === 3 && data.length === 4
                    ? 8
                    : idx === 6 && data.length === 8
                    ? 4
                    : 0,
              }}
              xl={{
                span: 8,
                offset:
                  // eslint-disable-next-line no-nested-ternary
                  idx === 3 && data.length === 4
                    ? 8
                    : idx === 6 && data.length === 8
                    ? 4
                    : 0,
              }}
              xs={{ span: 24, offset: 0 }}
            >
              <div className="item-image">
                <Image
                  imageName={item.img}
                  style={{
                    height: "40px",
                    width: "40px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              </div>
              <p className="item-content "> {item.content}</p>
              <p className="content text-center text-[#6E7682] text-[16px]">
                {item.desc}
              </p>
            </Col>
          ))}
        </Row>
        {!noCta && (
          <Row align="center" width="100%">
            <CtaPrimary ctaTitle="Learn more" target="/associations/demo" />
          </Row>
        )}
      </div>

      <style jsx global>{`
        .restuarant {
          margin-top: 80px;
          position: relative;
          overflow: hidden;
        }
        .restuarant-main-title {
          font-size: 38px;
          font-weight: 700;
          line-height: 1.45;
          color: #090d12;
          text-align: center;
        }
        .restuarant-main-para {
          font-family: sofia-pro, sans-serif;
          margin: 0 15%;
          text-align: center;
          color: #596779;
          font-size: 18px;
        }
        @media (min-width: 768px) and (max-width: 1260px) {
          .restuarant-main-title {
            font-size: 36px !important;
          }
        }
        .restuarant-main-para {
          font-family: sofia-pro, sans-serif;
          margin: 0 15%;
        }
        .restuarant .hardware {
          margin: 40px 0;
        }
        .restuarant .restaurant-features {
          padding: 0 100px;
        }
        .restuarant .item-image {
          width: 64px;
          height: 64px;
          position: relative;
          border-radius: 4px;
          background-color: #e8f0ff;
          display: table;
          margin: 0 auto;
        }
        .restuarant .item-content {
          margin-top: 16px;
          font-family: sofia-pro, sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 800;
          line-height: 19px;
          text-align: center;
          margin-bottom: 0;
        }
        .restuarant .content {
          margin-top: 8px;
          padding: 0 14px 14px 14px;
        }
        @media screen and (max-width: 768px) {
          .restuarant {
            padding: 32px 14px 0 14px;
            margin-top: 40px !important;
            margin-bottom: 20px !important;
          }
          .restuarant .restaurant-features {
            padding: 0;
          }
          .restuarant .hardware {
            min-height: 300px;
          }
          .restuarant-main-title {
            font-size: 24px;
          }
          .restuarant-main-para {
            margin: 0;
          }
          .restuarant .content {
            margin-top: 8px;
            font-size: 14px;
          }
        }
      `}</style>
    </div>
  );
};

Restaurants.propTypes = {
  data: PropTypes.string,
  nav: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  subtext: PropTypes.string,
  noHeading: PropTypes.bool,
};
Restaurants.defaultProps = {
  data: "",
  nav: "",
  title: "SpotOn Restaurant",
  image: "hardware.png",
  noHeading: false,
  subtext:
    "Are you looking to simplify your operation while boosting sales? It’s now easier than ever before with SpotOn. Our platform is not only easy to learn but also cloud-based, ensuring the best performance—always.",
};

export default Restaurants;
